/* .diagnostic-form-drawer .ant-modal-wrap {
  position: absolute;
} */
.diagnostic-form-drawer .saving-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  z-index: 5000;
  background: rgba(255, 255, 255, 0.3);
}
.diagnostic-form-drawer .saving-spinner.filled-background {
  background: rgba(255, 255, 255, 1);
}

.diagnostic-form-drawer .complete-diag-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.diagnostic-form-drawer .complete-diag-container .ant-btn.ant-btn-default,
.diagnostic-form-drawer .complete-diag-container .ant-btn.ant-btn-primary {
  width: 280px !important;
  max-width: 280px;
  height: 40px !important;
}
.diagnostic-form-drawer .complete-diag-container .ant-btn.ant-btn-default {
  border: none;
}
.diagnostic-form-drawer .complete-diag-container .ant-btn span {
  text-align: center;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
}
.diagnostic-form-drawer.ant-drawer-open {
  width: 100% !important;
  width: calc(100% - 225px) !important;
  right: 0 !important;
  left: unset;
}
.diagnostic-form-drawer .ant-drawer-body {
  padding: var(--offset-navbar-desktop) 0 40px 0;
  background: #fff;
}
.diagnostic-form-drawer .ant-drawer-close .desktop-close-button {
  display: block;
}
.diagnostic-form-drawer .ant-drawer-close .mobile-close-button {
  display: none;
  color: var(--primary-color);
  font-size: 18px;
  font-weight: 400;
}
.diagnostic-form-drawer .ant-drawer-header {
  display: none;
}
.diagnostic-form-drawer .ant-drawer-mask {
  left: -225px;
}
@media (min-width: 992px) {
  .diagnostic-form-drawer.ant-drawer-open .ant-drawer-content-wrapper {
    width: calc(100vw - 61px) !important;
  } 
  .expanded-menu .diagnostic-form-drawer.ant-drawer-open .ant-drawer-content-wrapper {
    width: calc(100vw - 300px) !important;
  }
}
@media (max-width: 992px) {
  .diagnostic-form-drawer.ant-drawer-open .ant-drawer-content-wrapper {
    width: 100% !important;
    box-shadow: none;
  }
  .diagnostic-form-drawer.ant-drawer-open {
    width: 100% !important;
    top: 65px;
  }
  .diagnostic-form-drawer .ant-drawer-body {
    padding: 20px 0px 30px 20px;
  }
  .diagnostic-form-drawer .ant-drawer-close .desktop-close-button {
    display: none;
  }
  .diagnostic-form-drawer .ant-drawer-close .mobile-close-button {
    display: block;
  }
  .diagnostic-form-drawer .ant-drawer-close {
    padding: 0;
  }
  .diagnostic-form-drawer .ant-drawer-mask {
    top: -65px;
  } 
}
.diagnostic-form-drawer .content-wrapper {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 20px;
}
.diagnostic-form-drawer .content-wrapper .content-section {
  background: #F8F8F8;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.diagnostic-form-drawer .content-wrapper .content-section.no-background {
  background: unset;
}
.diagnostic-form-drawer .content-wrapper .content-section .title {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
}
.diagnostic-form-drawer .content-wrapper .content-section .subtitle {
  color: #444;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 10px;
}
@media (max-width: 1200px) {
  .diagnostic-form-drawer .content-wrapper {
    max-width: 600px;
  }
}
@media (max-width: 992px) {
  .diagnostic-form-drawer .content-wrapper {
    max-width: 768px;
  }
  .diagnostic-form-drawer .content-wrapper .content-section {
    margin-right: 20px;
  }
}
