.quotation-totals {

}
/* details lines */
.quotation-totals .details-container {
  
}

.quotation-totals .description {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
}
.quotation-totals .value {
  color: #444;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

/* totals */
.quotation-totals .total-container {
  text-align: center;
  border-top: 1px solid #DBDBDB;
  padding-top: 10px;
}
.quotation-totals .total-container .value {
  color: var(--primary-color);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}
