.zindex-5002 {
  z-index: 5002;
}
.diagnostic-form-drawer .header-info {
  padding: 0;
  margin-bottom: 0px;
  border-bottom: 1px solid #CCC;
}

/* Header title */
.diagnostic-form-drawer .header-info .drawer-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}
.diagnostic-form-drawer .header-info .drawer-title h1 {
  line-height: 20px;
  margin-bottom: 0;
  color: #444;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: none;
}
/* Close button */
.diagnostic-form-drawer .header-info .drawer-title .close-btn {
  border: 0;
  box-shadow: none;
  padding: 0;
  width: 24px;
  height: 24px;
}
@media (min-width: 1200px) {
  .diagnostic-form-drawer .header-info .drawer-title .close-btn {
    position: absolute;
    left: 20px;
    top: calc(var(--offset-navbar-desktop) - 3px);
  }
}
.diagnostic-form-drawer .header-info .drawer-title .close-btn path {
  fill: var(--primary-color);
}

/* Appointment Resume */
.diagnostic-form-drawer .header-info .appointment-resume {
  padding: 0 10px 10px 10px;
}

/* Fields sections */
.diagnostic-form-drawer .header-info .field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 10px;
}
.diagnostic-form-drawer .header-info .field .description {
  font-size: 12px;
  line-height: 1;
  font-weight: 600;
  margin: 0 0 5px 0;
  color: var(--primary-color);
}
.diagnostic-form-drawer .header-info .field .value {
  font-size: 14px;
  line-height: 1;
  margin: 0 0 5px 0;
  color: #000;
}
.diagnostic-form-drawer .header-info .field .value:last-of-type {
  margin-bottom: 0;
}
