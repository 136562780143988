.diagnostic-form-drawer .attachments-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
  margin-top: 10px;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
}
.diagnostic-form-drawer .attachments-wrapper .file-preview {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  width: calc(50% - 5px);
  max-width: 300px;
}
.diagnostic-form-drawer .attachments-wrapper .file-preview .ant-btn {
  border: 0;
  box-shadow: unset;
}
.diagnostic-form-drawer .attachments-wrapper .file-preview .ant-btn span {
  color: var(--primary-color);
}
.diagnostic-form-drawer .attachments-wrapper .file-preview.audio {
  align-items: center;
}

.diagnostic-form-drawer .attachments-wrapper .file-preview.video video {
  width: calc(100% - 30px);
}
@media (max-width: 600px) {
  .diagnostic-form-drawer .attachments-wrapper .file-preview.audio {
    width: 100%;
  }
}
