.team-member-selection {
  padding: 20px 10px !important;
}

/* card grid */
.team-member-selection .card-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}

/* user card */
.team-member-selection .user-card {
  border-radius: 10px;
  cursor: pointer;
  width: calc(50% - 5px);
  border: 2px solid var(--primary-color);
  background-color: #fff;
  padding: 10px;
  margin: 0 auto;
}
.team-member-selection .user-card.not-clickable {
  cursor: not-allowed;
}
.team-member-selection .user-card .ant-image {
  width: 80px;
  height: 80px;
  margin: 0 auto 5px auto;
  display: block;
  border-radius: 50%;
  overflow: hidden;
}
.team-member-selection .user-card figcaption {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.team-member-selection .user-card figcaption img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.team-member-selection .user-card .anticon {
  background: #fff;
  color: var(--mariner-color);
  border-radius: 50%;
  padding: 2px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.team-member-selection .user-card p {
  color: #000;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}
.team-member-selection .user-card.selected {
  background-color: var(--primary-color);
}
.team-member-selection .user-card.selected p {
  color: #fff;
}
